import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule} from '@angular/material/core'
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule } from '@angular/material/input'
import {MatCheckboxModule } from '@angular/material/checkbox'
import {MatSliderModule } from '@angular/material/slider'
import {MatToolbarModule} from '@angular/material/toolbar'
import {MatDatepickerModule} from '@angular/material/datepicker'
import {MatGridListModule} from '@angular/material/grid-list'
import { MatMenuModule} from '@angular/material/menu'
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { FlexLayoutModule} from '@angular/flex-layout'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RecipeListComponent } from './pages/recipe-list/recipe-list.component';
import { RecipeDetailComponent } from './pages/recipe-detail/recipe-detail.component';
import { RecipeItemComponent } from './pages/recipe-list/recipe-item/recipe-item.component';
import { RecipeItemListComponent } from './pages/recipe-list/recipe-item/recipe-item-list.component';
import { SeedData } from './seed/seeddata';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RecipeStepsComponent } from './pages/recipe-detail/recipe-steps/recipe-steps.component';
import { AddEditStepComponent } from './pages/recipe-detail/recipe-steps/step/addedit/addedit.component';
import { RecipeIngredientsComponent } from './pages/recipe-detail/recipe-ingredients/recipe-ingredients.component';
import { AddEditRecipeIngredientComponent } from './pages/recipe-detail/recipe-ingredients/addedit/addedit.component';
import { ImageListComponent } from './components/image-list/image-list.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatSnackBarModule} from '@angular/material/snack-bar'
import {MatDialogModule} from '@angular/material/dialog'
import {DateTimeComponent} from './components/date/date'
import {MatTabsModule} from '@angular/material/tabs';
import { ListIngredientsComponent } from './pages/ingredients/list-ingredients/list-ingredients.component';
import { AddEditIngredientComponent } from './pages/ingredients/addeditingredient/addeditingredient.component';
import { UpdateService } from './services/Update.service';
import { ShareRecipeComponent } from './pages/sharing/recipe/recipe.component';
import {  ImportRecipeComponent } from './pages/sharing/recipe/importrecipe/import.component';
import { AboutComponent } from './pages/about/about.component';
@NgModule({
  declarations: [
    AppComponent,
    RecipeListComponent,
    RecipeDetailComponent,
    RecipeItemComponent,
    RecipeItemListComponent,
    RecipeStepsComponent,
    AddEditStepComponent,
    RecipeIngredientsComponent,
    AddEditRecipeIngredientComponent,
    ImageListComponent,DateTimeComponent, ListIngredientsComponent, AddEditIngredientComponent, ShareRecipeComponent, ImportRecipeComponent, AboutComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatCardModule,MatButtonModule, MatListModule,MatIconModule,MatCheckboxModule,MatSliderModule,
    FlexLayoutModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatToolbarModule,
    MatDatepickerModule,
    MatGridListModule,
    MatTableModule,
    FormsModule, ReactiveFormsModule,MatInputModule,MatSnackBarModule,MatDialogModule, MatNativeDateModule
    ,MatTabsModule
    ,MatMenuModule
  ],
  providers: [SeedData, UpdateService],
  bootstrap: [AppComponent]
})
export class AppModule { }
