<mat-list-item>
  <img matListAvatar  [routerLink]="'/recipe/' + recipe.Id" [src]='recipe.Photos != null && recipe.Photos.length>0 ? recipe.Photos[0] : "assets/baked-goods.jpg"' alt = "Recipe Image">

      
      <h2 matLine [routerLink]="'/recipe/' + recipe.Id">{{recipe.Name}}</h2>
      <p matLine [routerLink]="'/recipe/' + recipe.Id">
        {{recipe.Description}}
        <br/>
        Hydration: <b>{{recipe.GetHydration()}}%</b>
      </p>
      <div fxFlex></div>
      <button (click)="ShareRecipe(recipe);" mat-icon-button color="primary" aria-label="Share Ingredient">
        <mat-icon>share</mat-icon>
      </button>
      
      <button (click)="DeleteRecipe(recipe);" mat-icon-button color="warn" aria-label="Delete Ingredient">
        <mat-icon>delete</mat-icon>
      </button>
    </mat-list-item>
    <mat-divider></mat-divider>