import {Injectable, Type} from '@angular/core';
import * as idb from 'idb';
import {Observable, Subject} from 'rxjs';
import { Recipe } from '../model/Recipe';
import { Ingredient } from '../model/Ingredient';
import { SeedData } from '../seed/seeddata';
import { RecipeIngredient } from '../model/RecipeIngredient';
import { Step } from '../model/Step';
import { IngredientType } from '../enums/IngredientType.enum';

@Injectable({
  providedIn: 'root'
})
export class IdbService {
  private _recipeDataChange: Subject<Recipe> = new Subject<Recipe>();
  private _ingredientDataChange: Subject<Ingredient> = new Subject<Ingredient>();
  private _dbPromise;
private databaseName: string = 'breadbaker-db';
  

constructor(public seed: SeedData) {
}

connectToIDB() {
  
  var self = this;
  this._dbPromise =  idb.openDB(this.databaseName,12, {
    upgrade(db, oldVersion, newVersion, transaction)  {
     
      if (!db.objectStoreNames.contains("ingredients")) {
        const ingredientStore = db.createObjectStore("ingredients", {keyPath: 'Id', autoIncrement:false});

        self.seed.ingredients.forEach(ingredient => ingredientStore.add(ingredient));
      } 
      if (!db.objectStoreNames.contains("recipes")) {
        const recipeStore = db.createObjectStore("recipes", {keyPath: 'Id', autoIncrement:false});

        self.seed.recipes.forEach(recipe => recipeStore.add(recipe));
      } 

      if(oldVersion<11)
      {
        self.getAllIngredients().then((ingredients: Ingredient[])=>ingredients.forEach(ingredient=> {
          self.UpdateIngredientWithHydration(ingredient);
          self.addIngredient(ingredient);
        })).finally(()=>{
          self.getAllRecipes().then((recipes: Recipe[])=>recipes.filter(x=>{

            x.Ingredients.forEach(ri=> {self.UpdateIngredientWithHydration(ri.Ingredient)});
            self.addRecipe(x);
            })
              );
        });

        

       }
      }
  });
}

  private UpdateIngredientWithHydration(ingredient: Ingredient) {
    if (ingredient.Type == IngredientType.Flour) {
      ingredient.UseInHydration = true;
      ingredient.WaterPercentage = 0;
    }
    else if (ingredient.Type == IngredientType.StarterOrLeaven) {
      ingredient.UseInHydration = true;
      ingredient.WaterPercentage = 50;
    }
    else if (ingredient.Type == IngredientType.Liquid) {
      ingredient.UseInHydration = true;
      ingredient.WaterPercentage = 100;
    }
    else {
      ingredient.UseInHydration = false;
      ingredient.WaterPercentage = 0;
    }
  }

addRecipe(value: Recipe) {
  this._dbPromise.then((db: any) => {
    const tx = db.transaction(["recipes"], 'readwrite');
    tx.objectStore("recipes").put(value);
    if( typeof value.ChangesSaved==="function")
    {
      value.ChangesSaved();
    }
  this.getAllRecipes().then((items: Recipe) => {
    this._recipeDataChange.next(items);
  });
    return tx.complete;
  });
}

deleteRecipe(value: Recipe) {
  this._dbPromise.then((db: any) => {
    const tx = db.transaction(["recipes"], 'readwrite');
    const store = tx.objectStore("recipes");
    store.delete(value.Id);
    this.getAllRecipes().then((items: Recipe) => {
      this._recipeDataChange.next(items);
    });
  return tx.complete;
  });
}
getRecipe(id:string) {
  
  return this._dbPromise.then((db: any) => {

    const tx = db.transaction(["recipes"], 'readonly');
    const store = tx.objectStore("recipes");
    var recipe = store.get(id);
    return recipe.then(x=> {
      var obj = Object.setPrototypeOf(x, Recipe.prototype);
      obj.Ingredients.forEach(ing=>{

        Object.setPrototypeOf(ing, RecipeIngredient.prototype);
        Object.setPrototypeOf(ing.Ingredient, Ingredient.prototype);
      })
      obj.Steps.forEach(step=>{
        Object.setPrototypeOf(step, Step.prototype);
      })
      return obj;
    });
  });
}

getAllRecipes() {
  return this._dbPromise.then((db: any) => {
    const tx = db.transaction(["recipes"], 'readonly');
    const store = tx.objectStore("recipes");
    return store.getAll().then(x=>x.map(rec => Object.setPrototypeOf(rec, Recipe.prototype)));
  });
}

recipeDataChanged(): Observable<Recipe> {
    return this._recipeDataChange;
  }

  addIngredient(value: Ingredient) {
    this._dbPromise.then((db: any) => {
      const tx = db.transaction(["ingredients"], 'readwrite');
      tx.objectStore("ingredients").put(value);
    this.getAllIngredients().then((items: Ingredient) => {
      this._ingredientDataChange.next(items);
    });
      return tx.complete;
    });
  }
  
  deleteIngredient(value: Ingredient) {
    this._dbPromise.then((db: any) => {
      const tx = db.transaction(["ingredients"], 'readwrite');
      const store = tx.objectStore("ingredients");
      store.delete(value.Id);
      this.getAllIngredients().then((items: Ingredient) => {
        this._ingredientDataChange.next(items);
      });
    return tx.complete;
    });
  }
  
  getAllIngredients() {
    return this._dbPromise.then((db: any) => {
      const tx = db.transaction(["ingredients"], 'readonly');
      const store = tx.objectStore("ingredients");
      return store.getAll().then(x=>x.map(rec => Object.setPrototypeOf(rec, Ingredient.prototype)));
    });
  }

  
  
  ingredientDataChanged(): Observable<Ingredient> {
      return this._ingredientDataChange;
    }
    async exportAllData()
    {
      var idbDatabase = await new Promise((resolve, reject) => {
        var request = window.indexedDB.open(this.databaseName)
        request.onerror = () => reject('Could not open the database')
        request.onsuccess = () => resolve(request.result)
      });

        return this.exportToJson(idbDatabase);
      }
    
    private exportToJson(idbDatabase) {
      return new Promise((resolve, reject) => {
        const exportObject = {}
        if (idbDatabase.objectStoreNames.length === 0) {
          resolve(JSON.stringify(exportObject))
        } else {
          const transaction = idbDatabase.transaction(
            idbDatabase.objectStoreNames,
            'readonly'
          )
    
          transaction.addEventListener('error', reject)
    
          for (const storeName of idbDatabase.objectStoreNames) {
            const allObjects = []
            
            transaction
              .objectStore(storeName)
              .openCursor()
              .onsuccess = event => {
                const cursor = event.target.result
                if (cursor) {
                  // Cursor holds value, put it into store data
                  allObjects.push(cursor.value)
                  cursor.continue()
                } else {
                  // No more values, store is done
                  exportObject[storeName] = allObjects
    
                  // Last store was handled
                  if (
                    idbDatabase.objectStoreNames.length ===
                    Object.keys(exportObject).length
                  ) {
                    resolve(JSON.stringify(exportObject))
                  }
                }
              }
          }
        }
      })
    }
    ResetData()
    {
      var self = this;
      return this._dbPromise.then((db: any) => {
        if (!db.objectStoreNames.contains("ingredients")) {
          const ingredientStore = db.createObjectStore("ingredients", {keyPath: 'Id', autoIncrement:false});
        }
        if (!db.objectStoreNames.contains("recipes")) {
          const recipeStore = db.createObjectStore("recipes", {keyPath: 'Id', autoIncrement:false});
  
        } 
        const tx = db.transaction(["ingredients", "recipes"], 'readwrite');
        const ingredients = tx.objectStore("ingredients");
        ingredients.clear();
        const recipes = tx.objectStore("recipes");
        recipes.clear();
        self.seed.ingredients.forEach(ingredient => ingredients.put(ingredient));
    self.seed.recipes.forEach(recipe => recipes.put(recipe));

      });
        
     
    }
    importAllData(json:string)
    {
      return this._dbPromise.then((db: any) => {
        return this.importFromJson(db, json);
      });
    }
    private importFromJson(idbDatabase, json) {
      return new Promise((resolve, reject) => {
        const transaction = idbDatabase.transaction(
          idbDatabase.objectStoreNames,
          'readwrite'
        )
        transaction.addEventListener('error', reject)
    
        var importObject = JSON.parse(json)
        for (const storeName of idbDatabase.objectStoreNames) {
          let count = 0
          for (const toAdd of importObject[storeName]) {

            var request = transaction.objectStore(storeName);
            
            request.put(toAdd);
       
          }
        }
        resolve("Success")
      })
    }
  }