
<button mat-flat-button color="primary" aria-label="add ingredient" (click)="AddIngredient()">
          add ingredient
        </button>
<mat-table [dataSource]="ingredients" class="mat-elevation-z8">
    <ng-container matColumnDef="ingredient">
        <mat-header-cell *matHeaderCellDef> Ingredient </mat-header-cell>
        <mat-cell *matCellDef="let ingredient" (click)="EditIngredient(ingredient)"> {{ingredient.Ingredient.Name}} </mat-cell>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="percentage" >
        <mat-header-cell *matHeaderCellDef class="percentCell"> % </mat-header-cell>
        <mat-cell *matCellDef="let ingredient" (click)="EditIngredient(ingredient)" class="percentCell"> {{ingredient.Percentage}} </mat-cell>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="weight">
        <mat-header-cell *matHeaderCellDef  class="weightCell"> Weight </mat-header-cell>
        <mat-cell *matCellDef="let ingredient" (click)="EditIngredient(ingredient)"  class="weightCell"> {{ingredient.GetWeightForDisplay()}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete" >
        <mat-header-cell *matHeaderCellDef class="deleteCell"> </mat-header-cell>
        <mat-cell  *matCellDef="let ingredient" class="deleteCell">
            <button (click)="DeleteIngredient(ingredient);" mat-icon-button color="primary" aria-label="Delete Ingredient">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
    
   
    
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>
    </mat-table>