<mat-dialog-content>
    <h1>Share Recipe: {{recipe.Name}}</h1>
    <p>To keep you recipes private we allow you to download the Recipe to your device and share it with whomever you like.</p>

    <h2>Get my recipe</h2>
    <p>Click the button below and you'll download a .dough file that represents your recipe, when you share this file other users can simply visit the <a href="https://breadbaker.noplaytime.com/import-recipe">import section</a> to add it to their recipe list</p>
    <p>The import section can be found under the "menu" item in the toolbar of the recipe list page</p>

</mat-dialog-content>
<mat-dialog-actions>
    <a [download]="MakeFileNameSafe(recipe.Name)" (click)="DownloadClicked()" [href]="recipeJsonAsDownload" mat-raised-button color="primary">Download</a>
    <a [download]="MakeFileNameSafe(recipe.Name)" (click)="DownloadClicked()" [href]="recipeWithoutPhotosJsonAsDownload" mat-raised-button color="primary">Download without photos</a>
</mat-dialog-actions>