import { IRecipe } from './interface/Irecipe';
import { Step } from './Step';
import { RecipeIngredient } from './RecipeIngredient';
import { IngredientType } from '../enums/IngredientType.enum';

import {ValidationResponse} from './ValidationResponse'
export class Recipe implements IRecipe {

    Id:string ;   
    Name: string;
    Description: string;
    Notes: string;
    Steps: Step[] = [];
    Photos: string[] = [];
    Ingredients: RecipeIngredient[] = [];
    Weight: number;
    private UnsavedChanges: boolean = false;
    SetUnsavedChanges()
    {
        this.UnsavedChanges = true;
    }
    ChangesSaved()
    {
        this.UnsavedChanges = false;
    }
    IsUnsaved():boolean
    {
        return this.UnsavedChanges;
    }
    GetDoughWeightInGrams() : number {
        
        return this.Ingredients.reduce((sum, current) => sum + current.GetWeight(), 0);
    }
    GetHydration() : number {
        return Math.round(((this.GetWaterPercentageForHydration())  / (this.GetDryPercentageForHydration())) * 100);
    }


    private GetDryPercentageForHydration(): number
    {
        var dryPercentage = 0;
           
        dryPercentage = this.Ingredients.filter(i => i.Ingredient.UseInHydration).reduce((total, u) => total + (u.Percentage * this.GetIngredientDryFactor(u)), 0);
        
       return dryPercentage == 0 ? 1 : dryPercentage;

    }

    private GetWaterPercentageForHydration():number{
        var waterPercentage = 0;
        return    waterPercentage = this.Ingredients.filter(i => i.Ingredient.UseInHydration).reduce((total, u) => total + (u.Percentage * this.GetIngredientWaterFactor(u)), 0);
    }

    private GetIngredientWaterFactor(u: RecipeIngredient) {
        return u.Ingredient.WaterPercentage / 100;
    }
    private GetIngredientDryFactor(u: RecipeIngredient) {
        return 1 -(u.Ingredient.WaterPercentage / 100);
    }



    GetFlourWeightFromWeights(): number {
        return this.Ingredients.filter(i => i.Ingredient.Type == IngredientType.Flour).reduce((total, u) => total + u.GetWeight(), 0);
    }

    GetDoughWeightFromWeights(): number {
        return this.Ingredients.reduce((total, u) => total + u.GetWeight(), 0);
    }
    

    GetFormulaPercentage(): number {
        return this.Ingredients.reduce((total, u) => total + u.Percentage, 0);
    }

    ConvertRecipeToBakersPercentages()
    {
        this.Ingredients.forEach(ingredient => {
            ingredient.SetBakersPercentageFromWeight(this.GetFlourWeightFromWeights());
        });

        this.Weight = this.GetDoughWeightFromWeights();
    }

    UpdateIngredientWeights()
    {
        this.Ingredients.forEach(ingredient => {
            if(this.Weight == null)
            {
                ingredient.SetWeight(0);
            }
            else
            {
            ingredient.SetWeight(this.Weight * (ingredient.Percentage / this.GetFormulaPercentage()));
            }
        });
        this.Ingredients.sort((a, b) => +(b.Ingredient.Type==IngredientType.Flour ? 1000 :0) - (a.Ingredient.Type==IngredientType.Flour ? 1000 : 0) || b.Percentage -a.Percentage);
          
    }

    public IsTotalFlour100Percent() {
        return this.GetTotalFlourPercentage() == 100;
    }

    

    public GetTotalFlourPercentage() {
        return this.Ingredients.filter(i => i.Ingredient.Type == IngredientType.Flour).reduce((total, u) => total + u.Percentage, 0);
    }

    Validate(): ValidationResponse {
        if(!this.IsTotalFlour100Percent())
        {
            return new ValidationResponse(true, "Total flour percentage does not equal 100");
        }
        else if(this.Name == "")
        {
            return new ValidationResponse(true, "Name is required");
        }
        else if(this.Weight == null)
        {
            return new ValidationResponse(true, "A dough weight is required");
        }


        return new ValidationResponse(false, "Recipe is Valid");;

    }

    
}
