import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Step } from 'src/app/model/Step';

@Component({
  selector: 'app-addedit',
  templateUrl: './addedit.component.html',
  styleUrls: ['./addedit.component.scss']
})
export class AddEditStepComponent implements OnInit {
  title:string;
  step:Step = new Step();
  originalStep: Step;
  steps: Step[] = [];
  constructor(
    private dialogRef: MatDialogRef<AddEditStepComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.title = data.description;
    this.step = data.step;
    this.originalStep = Object.assign({},this.step );
   
}
compareStep = function( option, value ) : boolean {
  if(value==null)
  {
    return false;    
  }
  return option.Id === value.Id;
}
  ngOnInit(): void {
    
  }
  Close()
  {
    this.step.StartDateTime = this.originalStep.StartDateTime;
    this.step.EndDateTime = this.originalStep.EndDateTime;
    this.step.Name = this.originalStep.Name;
    this.step.TemperatureInC = this.originalStep.TemperatureInC;
    this.dialogRef.close();
  }

  Save()
  {
    this.dialogRef.close(this.step);
  }

  StringToDateTime(date: string):Date
  {
    return new Date(date);
  }
}
