<mat-toolbar  color="primary"  >  
    <mat-toolbar-row fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
      
    <button mat-raised-button  routerLink="/" color="secondary" aria-label="Back to recipes">
      Back to Recipes
    </button> 
    </mat-toolbar-row>
</mat-toolbar>
<div fxLayout="column" fxLayoutGap="10px" class="container">
<h1>BreadBaker</h1>
<p>BreadBaker is a progressive web app designed to help bakers build recipes for bread and more</p>
<ul>
    <li>It's free!</li>
    <li>It runs locally, none of your recipe data is stored online, it's stored directly on your device.</li>
    <li>Being a PWA it functions very much like an app, if you opt to save it to your home screen it practically works the same as an app (on modern, compatible devices at least).</li>
    <li>It's compatible with modern browsers.</li>
    <li>It's installable on most devices and works offline</li>
    <li>Available ingredients can be modified by the user</li>
    <li>Store lightweight images directly against your recipes of your bakes</li>
    <li>Recipes can be shared with others (there's no real backend so this feature is fairly primitive, but at least it lets you send a recipe to someone else)</li>
    <li>It works with baker's percentages so formulating recipes is quick and recipes can be quickly scaled to any batch size.</li>
</ul>
<p>It's still a work in progress. Any questions, feedback or suggestions are welcomed.</p>
</div>