import { IIngredient } from './interface/Iingredient';
import { IngredientType } from '../enums/IngredientType.enum';


export class Ingredient implements IIngredient {
    WaterPercentage: number = 0;
    UseInHydration: boolean = false;
    Id: string;
    Type: IngredientType;   
    Name: string;

}
