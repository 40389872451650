<button mat-flat-button color="primary" aria-label="add Step" (click)="AddStep()">
         add step
        </button>
<mat-table [dataSource]="steps" class="mat-elevation-z8">
    <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
        <mat-cell *matCellDef="let step" (click)="EditStep(step)"> {{step.Name}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="temperature">
        <mat-header-cell *matHeaderCellDef class="temperatureCell"> Temperature </mat-header-cell>
        <mat-cell *matCellDef="let step"  class="temperatureCell" (click)="EditStep(step)"> {{step.GetTemperatureForDisplay()}} </mat-cell>
      </ng-container>
    
      <!-- Name Column -->
      <ng-container matColumnDef="start" >
        <mat-header-cell *matHeaderCellDef class="dateCell"> Start </mat-header-cell>
        <mat-cell *matCellDef="let step" (click)="EditStep(step)"  class="dateCell"> {{step.StartDateTimeForDisplay()}} </mat-cell>
      </ng-container>
    
      <!-- Weight Column -->
      <ng-container matColumnDef="end">
        <mat-header-cell *matHeaderCellDef class="dateCell"> End </mat-header-cell>
        <mat-cell *matCellDef="let step" (click)="EditStep(step)"   class="dateCell"> {{step.EndDateTimeForDisplay()}} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="delete" >
        <mat-header-cell *matHeaderCellDef class="deleteCell"> </mat-header-cell>
        <mat-cell  *matCellDef="let step" class="deleteCell">
            <button (click)="DeleteStep(step);" mat-icon-button color="primary" aria-label="Delete Step">
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>
    
   
    
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>
    </mat-table>