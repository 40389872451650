import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { RecipeIngredient } from 'src/app/model/RecipeIngredient';

import { IdbService } from 'src/app/services/idb.service';
import { Ingredient } from 'src/app/model/Ingredient';
import { IngredientType } from 'src/app/enums/IngredientType.enum';

@Component({
  selector: 'app-addedit',
  templateUrl: './addedit.component.html',
  styleUrls: ['./addedit.component.scss']
})
export class AddEditRecipeIngredientComponent implements OnInit {
  title:string;
  ingredient:RecipeIngredient = new RecipeIngredient();
  originalIngredient: RecipeIngredient;
  ingredients: Ingredient[] = [];
  ingredientTypes: string[];
  constructor(
    private dialogRef: MatDialogRef<AddEditRecipeIngredientComponent>,
    private idbService: IdbService,
    @Inject(MAT_DIALOG_DATA) data) {

    this.title = data.title;
    this.ingredient = data.ingredient;
    this.originalIngredient = Object.assign({},this.ingredient );
   
    this.idbService.getAllIngredients().then(x=> {this.ingredients = x;});
    this.ingredientTypes = IngredientType.values();
}
GetIngredientsByType(type)
{
  return this.ingredients.filter(x=>IngredientType[x.Type]===type)
}

compareIngredient = function( option, value ) : boolean {
  if(value==null)
  {
    return false;    
  }
  return option.Id === value.Id;
}
  ngOnInit(): void {
    
  }
  Close()
  {
    this.ingredient.Percentage = this.originalIngredient.Percentage;
    this.ingredient.Ingredient = this.originalIngredient.Ingredient;
    this.dialogRef.close();
  }

  Save()
  {
    this.dialogRef.close(this.ingredient);
  }
}
