import { IStep } from './interface/Istep';
import { Time } from '@angular/common';


export class Step implements IStep {
   
    Id: string;    
    Name: string;
    TemperatureInC: number;
    EndDateTime: Date;
    StartDateTime: Date;

    GetTemperatureForDisplay(): string
    {
        return this.TemperatureInC + "c";
    }

    StartDateTimeForDisplay(): string {
        return this.StartDateTime.toLocaleString();
    }
    EndDateTimeForDisplay(): string {
        return this.EndDateTime.toLocaleString();
    }
}
