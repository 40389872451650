
<mat-toolbar  color="primary"  >  
  <mat-toolbar-row fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px">
    
  <button mat-raised-button  routerLink="/ingredients" color="secondary" aria-label="List Ingredients" >
    Ingredients
  </button> 

  <button routerLink="/recipe/add" mat-raised-button  color="secondary" aria-label="add ingredient">
    <mat-icon>add</mat-icon>
  </button>
  <button mat-icon-button (click)="toggleSearch()" aria-label="Search for recipes">
    <mat-icon>search</mat-icon>
  </button>
  <button mat-icon-button [matMenuTriggerFor]="appMenu" aria-label="More Options">
    <mat-icon>more_vert</mat-icon>
  </button>
</mat-toolbar-row >
  <mat-toolbar-row *ngIf="showSearch" fxLayout="grow" fxLayoutAlign="end center" >
  <mat-form-field class="searchbox"  appearance="standard">
    <mat-label>Search</mat-label>
    <input id="searchInput" matInput [ngModel]="searchTerm" (ngModelChange)="searchRecipes($event)" name="searchInput" (blur)="showSearch=false"/>
  </mat-form-field>
</mat-toolbar-row>
</mat-toolbar> 
<div fxLayout='row' fxLayout.xs="column" fxLayoutGap="25px" *ngIf="IsCardView()" >
  <app-recipe-item (recipeDeleted)="recipeDeleted()"  fxFlex="33" fxFlex.lg="25"  fxFlex.sm="50" fxFlex.xs="100" *ngFor="let recipe of recipes" [recipe]="recipe"></app-recipe-item>
  </div>
  <mat-list *ngIf="IsListView()" >
    <app-recipe-item-list (recipeDeleted)="recipeDeleted()" *ngFor="let recipe of recipes" [recipe]="recipe"></app-recipe-item-list>
    </mat-list>


<mat-menu #appMenu="matMenu">
  <button mat-menu-item routerLink="import-recipe" aria-label="Import/Export Data">Import/Export Data</button>
  <button mat-menu-item (click)="ChangeView()"   color="secondary" aria-label="change view">
    Change to {{GetNextView()}}
  </button>
  <button mat-menu-item routerLink="about" aria-label="About the app">About the app</button>
</mat-menu>

    