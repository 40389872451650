import { Component } from '@angular/core';
import { UpdateService } from './services/Update.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'BreadBaker';
  constructor(private update: UpdateService) {
    this.checkPersistData();
  }

  async checkPersistData()
  {
    navigator.storage.persist().then((granted) => {
      if (granted) {
        console.log(`Persisted storage granted: ${granted}`);
      }
    });
  }
}
