
<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
  
    <mat-form-field>
        <mat-label>Ingredient</mat-label>
        <mat-select name="ingredient" [(ngModel)]="ingredient.Ingredient" [compareWith]="compareIngredient">
          <mat-optgroup *ngFor="let ingredientType of ingredientTypes" [label]="ingredientType">
            
            <mat-option  required  *ngFor="let ing of GetIngredientsByType(ingredientType)" [value]="ing" >
              {{ing.Name}}
            </mat-option>
          </mat-optgroup>
          </mat-select>
      </mat-form-field>

      <mat-form-field >
        <mat-label>Percentage</mat-label>
        <input required type="number" matInput [(ngModel)]="ingredient.Percentage" name="percentage"/>
      </mat-form-field>
 
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="Close()" aria-label="Close Add/Edit Ingredient">Close</button>
    <button mat-raised-button color="primary" (click)="Save()" aria-label="Save Ingredient">Save</button>
</mat-dialog-actions>
