import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Ingredient } from 'src/app/model/Ingredient';
import { IngredientType } from 'src/app/enums/IngredientType.enum';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-addeditingredient',
  templateUrl: './addeditingredient.component.html',
  styleUrls: ['./addeditingredient.component.scss']
})
export class AddEditIngredientComponent implements OnInit {


  title:string;
  ingredient:Ingredient = new Ingredient();
  originalIngredient: Ingredient;
  IngredientTypeValues: string[];
  constructor(
    private dialogRef: MatDialogRef<AddEditIngredientComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private meta:Meta) {
      meta.addTag({ name: 'robots', content: 'noindex' });
    this.title = data.title;
    this.ingredient = data.ingredient;
    this.originalIngredient = Object.assign({},this.ingredient );
      this.IngredientTypeValues = IngredientType.values();
    
}
SetIngredientType(event:string)
{
  this.ingredient.Type = IngredientType[event];
}
WaterPercentageChange(event)
{
  this.ingredient.WaterPercentage=event.value;
}
UseInHydrationChange(event)
{
  
  this.ingredient.UseInHydration=event.checked;
}
GetIngredientTypeByName(it: IngredientType): string
{
  return IngredientType[it];
}
  ngOnInit(): void {
    
  }
  Close()
  {
    this.ingredient.Type = this.originalIngredient.Type;
    this.ingredient.Name = this.originalIngredient.Name;
    this.dialogRef.close();
  }

  Save()
  {
    this.dialogRef.close(this.ingredient);
  }
}
