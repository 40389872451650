import { Component, OnInit } from '@angular/core';
import { Recipe } from 'src/app/model/Recipe';
import { IdbService } from 'src/app/services/idb.service';
import { Router } from '@angular/router';
import { Guid } from 'guid-typescript';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-download',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportRecipeComponent implements OnInit {
  recipeJsonAsDownload: SafeUrl;
  constructor(private sanitization:DomSanitizer,private idbService: IdbService, private router: Router, private _snackBar: MatSnackBar) {
    idbService.connectToIDB();
   }
  fileCounter: number= 0;
  filesCount: number = 0;
  ngOnInit(): void {
  }

  ImportRecipe(evt)
  {
    this.fileCounter = 0;
    var files = evt.target.files;
    this.filesCount = files.length;
    for (let file of files) {
          var reader = new FileReader();
          reader.onload =this._handleReaderLoaded.bind(this);
          reader.readAsText(file);
      }; 
      }
    
    
      _handleReaderLoaded(readerEvt) {
        var binaryString = readerEvt.target.result;
        
        this.AddToRecipes(binaryString);
        this.fileCounter++;
        if(this.fileCounter >=this.filesCount)
        {
          this.router.navigate(['']);
        }
       }
    
       AddToRecipes(importRecipe: string)
    {
      
      let recipe: Recipe = JSON.parse(importRecipe);
      
      recipe.Id = Guid.create().toString();
      
      this.idbService.addRecipe(recipe);
    }

    async exportAllData()
    {
      
     this.idbService.exportAllData().then(x=>{
      this.recipeJsonAsDownload = this.sanitization.bypassSecurityTrustUrl("data:text/json;charset=utf-8," + x);
     });

    }
    ResetData()
    {
      if(confirm('This will remove all existing recipes, continue?'))
    {
      this.idbService.ResetData().then(x=>{ 

        let snackBarRef = this._snackBar.open("Reset Complete", "OK", {
          duration: 5000,
        });
        this.router.navigate(['']);});
    }
  }
    ImportData(evt)
  {
    if(confirm('This will overwrite existing recipes, continue?'))
    {
    var file = evt.target.files[0];
    
   
          var reader = new FileReader();
          reader.onload =this._handleReaderLoadedData.bind(this);
          reader.readAsText(file);
      } 
      
    }
      _handleReaderLoadedData(readerEvt) {
        var binaryString = readerEvt.target.result;
        ;
        this.idbService.importAllData(binaryString).then(x=>{ 
          let snackBarRef = this._snackBar.open("Import Complete", "OK", {
            duration: 5000,
          })
          ;this.router.navigate(['']);});
          
        
       }
}
