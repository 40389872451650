import { Guid } from 'guid-typescript';
import { Ingredient } from '../model/Ingredient';
import { IngredientType } from '../enums/IngredientType.enum';
import { RecipeIngredient } from '../model/RecipeIngredient';
import { Step } from '../model/Step';
import { Recipe } from '../model/Recipe';

export class SeedData {

  whiteFlourIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000001",
  Name: 'White Flour', 
  Type : IngredientType.Flour,
  UseInHydration: true,
  WaterPercentage:0
};
ryeWMFlourIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000002",
  Name: 'Rye Flour (wholemeal)', 
  Type : IngredientType.Flour,
  UseInHydration: true,
  WaterPercentage:0
};


wholemealFlourIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000003",
  Name: 'Wholemeal Flour', 
  Type : IngredientType.Flour ,
  UseInHydration: true,
  WaterPercentage:0
};

saltIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000004",
  Name: 'Salt', 
  Type : IngredientType.Salt  ,
  UseInHydration: false,
  WaterPercentage:0
};

waterIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000005",
  Name: 'Water', 
  Type : IngredientType.Liquid  ,
  UseInHydration: true,
  WaterPercentage:100
};

starterIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000006",
  Name: 'Starter', 
  Type : IngredientType.StarterOrLeaven  ,
  UseInHydration: true,
  WaterPercentage:50
};
yeastIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000007",
  Name: 'Dry Yeast', 
  Type : IngredientType.Yeast  ,
  UseInHydration: false,
  WaterPercentage:0
};
eggIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000008",
  Name: 'Egg', 
  Type : IngredientType.Fats  ,
  UseInHydration: true,
  WaterPercentage:75
};
butterIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000009",
  Name: 'Butter', 
  Type : IngredientType.Fats  ,
  UseInHydration: false,
  WaterPercentage:25
};
oilIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000010",
  Name: 'Oil', 
  Type : IngredientType.Fats  ,
  UseInHydration: false,
  WaterPercentage:25
};
sugarIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000011",
  Name: 'Sugar', 
  Type : IngredientType.Sugars  ,
  UseInHydration: false,
  WaterPercentage:0
};
honeyIngredient: Ingredient = 
  {
  Id: "00000000-0000-0000-0000-000000000012",
  Name: 'Honey', 
  Type : IngredientType.Sugars  ,
  UseInHydration: true,
  WaterPercentage:50
};

basicRecipeFlour= {Id:Guid.create().toString(), Percentage:100, Ingredient: this.whiteFlourIngredient} as RecipeIngredient;

basicRecipe = 
{
  Id: Guid.create().toString(),
  Name: "Example Bread Recipe",
  Weight: 750,
  Photos: [],
  Ingredients: [
    {Id:Guid.create().toString(), Percentage:100, Ingredient: this.whiteFlourIngredient } as RecipeIngredient,
    {Id:Guid.create().toString(), Percentage:65, Ingredient: this.waterIngredient } as RecipeIngredient,
    {Id:Guid.create().toString(), Percentage:1, Ingredient: this.saltIngredient } as RecipeIngredient,
    {Id:Guid.create().toString(), Percentage:0.5, Ingredient: this.yeastIngredient } as RecipeIngredient
  ],
  Steps:[
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 12:00:00'), EndDateTime:new Date('2020-04-17 12:15:00'), TemperatureInC:25, Name:"Autolyse"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 12:15:00'), EndDateTime:new Date('2020-04-17 12:20:00'), TemperatureInC:25, Name:"Add Salt and Yeast"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 12:20:00'), EndDateTime:new Date('2020-04-17 12:30:00'), TemperatureInC:25, Name:"Knead"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 12:30:00'), EndDateTime:new Date('2020-04-17 13:15:00'), TemperatureInC:25, Name:"Bulk Fermentation"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 13:15:00'), EndDateTime:new Date('2020-04-17 13:30:00'), TemperatureInC:25, Name:"PreShape"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 13:30:00'), EndDateTime:new Date('2020-04-17 14:00:00'), TemperatureInC:25, Name:"Shape and proof"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 14:00:00'), EndDateTime:new Date('2020-04-17 14:20:00'), TemperatureInC:220, Name:"Bake lid on"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 14:20:00'), EndDateTime:new Date('2020-04-17 14:40:00'), TemperatureInC:200, Name:"Bake lid off"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 14:40:00'), EndDateTime:new Date('2020-04-17 16:30:00'), TemperatureInC:25, Name:"cool on Rack"} as Step
  ],
  Description: "BreadBaker allows you to create, share and manage recipes for baking bread and more. This is a sample recipe, not to be followed, and serves as an example of this apps capabilities",
  Notes: "hot summer day last time this baked, over proofed but crispy"
} as Recipe;

basicSourdoughRecipe = 
{
  Id: Guid.create().toString(),
  Name: "Example Basic Sourdough Recipe",
  Weight: 750,
  Photos: [],
  Ingredients: [
    {Id:Guid.create().toString(), Percentage:90, Ingredient: this.whiteFlourIngredient } as RecipeIngredient,
    {Id:Guid.create().toString(), Percentage:10, Ingredient: this.wholemealFlourIngredient } as RecipeIngredient,
    {Id:Guid.create().toString(), Percentage:70, Ingredient: this.waterIngredient } as RecipeIngredient,
    {Id:Guid.create().toString(), Percentage:1.5, Ingredient: this.saltIngredient } as RecipeIngredient,
    {Id:Guid.create().toString(), Percentage:15, Ingredient: this.starterIngredient } as RecipeIngredient
  ],
  Steps:[
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 12:00:00'), EndDateTime:new Date('2020-04-17 12:30:00'), TemperatureInC:25, Name:"Autolyse"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 12:30:00'), EndDateTime:new Date('2020-04-17 12:45:00'), TemperatureInC:25, Name:"Add Starter"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 12:45:00'), EndDateTime:new Date('2020-04-17 13:00:00'), TemperatureInC:25, Name:"Add Salt and fold"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 13:30:00'), EndDateTime:new Date('2020-04-17 13:31:00'), TemperatureInC:25, Name:"Slap and Fold"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 14:00:00'), EndDateTime:new Date('2020-04-17 14:01:00'), TemperatureInC:25, Name:"Slap and Fold"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 14:30:00'), EndDateTime:new Date('2020-04-17 14:31:00'), TemperatureInC:25, Name:"Slap and Fold"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 15:00:00'), EndDateTime:new Date('2020-04-17 15:01:00'), TemperatureInC:25, Name:"Slap and Fold"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 15:30:00'), EndDateTime:new Date('2020-04-17 15:31:00'), TemperatureInC:25, Name:"Slap and Fold"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 15:45:00'), EndDateTime:new Date('2020-04-17 20:30:00'), TemperatureInC:25, Name:"Bulk Fermentation"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 20:30:00'), EndDateTime:new Date('2020-04-17 20:35:00'), TemperatureInC:25, Name:"PreShape"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-17 20:45:00'), EndDateTime:new Date('2020-04-18 07:00:00'), TemperatureInC:5, Name:"Shape and proof in fridge"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-18 07:00:00'), EndDateTime:new Date('2020-04-18 07:40:00'), TemperatureInC:240, Name:"Bake lid on"} as Step,
    {Id: Guid.create().toString(), StartDateTime:new Date('2020-04-18 07:40:00'), EndDateTime:new Date('2020-04-18 10:30:00'), TemperatureInC:25, Name:"cool on Rack"} as Step
  ],
  Description: "this is a sample recipe, not to be followed, and serves as an example of this apps capabilities",
  Notes: "cold miserable day, starter was not hugely active and bulk fermentation took a little longer than expected."
} as Recipe;


  ingredients:Ingredient[] = [
    this.whiteFlourIngredient,
    this.wholemealFlourIngredient,
    this.saltIngredient,
    this.waterIngredient,
    this.yeastIngredient,
    this.starterIngredient,
    this.ryeWMFlourIngredient,
    this.oilIngredient,
    this.sugarIngredient,
    this.honeyIngredient,
    this.butterIngredient,
    this.eggIngredient
    
  ];
  
  recipes:Recipe[] = [
    this.basicRecipe,
    this.basicSourdoughRecipe
  ];
}
