<mat-card   class="recipeItem">
  <img  [routerLink]="'/recipe/' + recipe.Id" mat-card-image [src]='recipe.Photos != null && recipe.Photos.length>0 ? recipe.Photos[0] : "assets/baked-goods.jpg"' alt = "Recipe Image">
    <mat-card-header  [routerLink]="'/recipe/' + recipe.Id">
      
      <mat-card-title>{{recipe.Name}}</mat-card-title>
      <mat-card-subtitle>Hydration: <b>{{recipe.GetHydration()}}%</b></mat-card-subtitle>

    </mat-card-header>
    
    <mat-card-content  [routerLink]="'/recipe/' + recipe.Id">
      <p>
        {{recipe.Description}}
      </p>
    </mat-card-content>
    <mat-card-actions align="end">
      <button (click)="ShareRecipe(recipe);" mat-icon-button color="primary" aria-label="Share Ingredient">
        <mat-icon>share</mat-icon>
      </button>
      <div fxFlex></div>
      <button (click)="DeleteRecipe(recipe);" mat-icon-button color="warn" aria-label="Delete Ingredient">
        <mat-icon>delete</mat-icon>
      </button>
      
    </mat-card-actions>
    
  </mat-card>
