<mat-dialog-content>
    <mat-form-field >
        <mat-label>Name</mat-label>
        <input required matInput [(ngModel)]="ingredient.Name" name="name"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Type</mat-label>
        <mat-select name="type" [ngModel]="GetIngredientTypeByName(ingredient.Type)" (ngModelChange)="SetIngredientType($event)" >
            <mat-option   required  *ngFor="let ing of IngredientTypeValues" [value]="ing" >
              {{ing}}
            </mat-option>
          </mat-select>
      </mat-form-field>

      <br/>
        <mat-checkbox name="useInHydration"  [checked]="ingredient.UseInHydration" (change)="UseInHydrationChange($event)"  >Use In Hydration</mat-checkbox>

        <br/>
        <mat-label *ngIf="ingredient.UseInHydration">Water Content as Percentage </mat-label> 
        <mat-slider *ngIf="ingredient.UseInHydration"   thumbLabel   name="waterPercentage" (change)="WaterPercentageChange($event)" [value]="ingredient.WaterPercentage" min="0" max="100" step="1" ></mat-slider>

</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="Close()" Aria-label="Left Align">Close</button>
    <button mat-raised-button color="primary" (click)="Save()">Save</button>
</mat-dialog-actions>
