import { Component, OnInit,  KeyValueDiffer, KeyValueDiffers, KeyValueChanges, KeyValueChangeRecord } from '@angular/core';
import { Recipe } from 'src/app/model/Recipe';
import { ActivatedRoute, Router } from '@angular/router';
import { IdbService } from 'src/app/services/idb.service';
import { Guid } from 'guid-typescript';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RecipeIngredient } from 'src/app/model/RecipeIngredient';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Step } from 'src/app/model/Step';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-recipe-detail',
  templateUrl: './recipe-detail.component.html',
  styleUrls: ['./recipe-detail.component.scss']
})
export class RecipeDetailComponent implements OnInit {
  private recipeDiffer: KeyValueDiffer<string, any>;
  recipe: Recipe = new Recipe();
  constructor(private fb: FormBuilder,private activatedRoute: ActivatedRoute, private idbService: IdbService, private router: Router,private differs: KeyValueDiffers,private _snackBar: MatSnackBar,private meta: Meta) {
    meta.addTag({ name: 'robots', content: 'noindex' });
      idbService.connectToIDB();

      if(this.activatedRoute.snapshot.paramMap.get('id') != null)
      {
    idbService.getRecipe(this.activatedRoute.snapshot.paramMap.get('id')).then(x=>{
      this.recipe = x;
      this.SortSteps();
      this.recipe.UpdateIngredientWeights();
      this.recipeDiffer = this.differs.find(this.recipe).create();

    });
  }
  else
  {
    this.recipe = new Recipe();
    
    this.recipe.Id = Guid.create().toString();
    this.recipeDiffer = this.differs.find(this.recipe).create();
    
  }
}

SortSteps()
{
  this.recipe.Steps.sort((a: Step, b: Step) => {
    return a.StartDateTime > b.StartDateTime ? 1 : (a.StartDateTime < b.StartDateTime ?-1 :0)
});
}
IngredientsUpdated(ingredients : RecipeIngredient[])
{
  this.recipe.Ingredients = ingredients;
  this.recipe.UpdateIngredientWeights();
  this.recipe.SetUnsavedChanges();
}
RemoveIngredient(ingredient: RecipeIngredient)
{
  this.recipe.Ingredients = this.recipe.Ingredients.filter(x=>x!==ingredient);
  this.recipe.UpdateIngredientWeights();
}
recipeChanged(changes: KeyValueChanges<string, any>) {

  changes.forEachChangedItem(
    (record: KeyValueChangeRecord<string, any>) => {
    if(record.key != "UnsavedChanges")
    {
    this.recipe.SetUnsavedChanges()}}
    ); 
}

StepsUpdated(steps: Step[])
{
  this.recipe.Steps = steps;
  this.recipe.SetUnsavedChanges();
}
RemoveStep(step: Step)
{
  this.recipe.Steps = this.recipe.Steps.filter(x=>x!==step);
}
RemovePhoto(index: number)
{
  this.recipe.SetUnsavedChanges();
  this.recipe.Photos.splice(index, 1);
}
ngDoCheck(): void {
  
  if(this.recipeDiffer != null)
  {
    
    const changes = this.recipeDiffer.diff(this.recipe);
    if (changes != null) {
      this.recipeChanged(changes);
    }
  }
}
  Close()
  {
    if(!this.recipe.IsUnsaved() || confirm('There are unsaved changes? changes will be lost'))
    {
      this.router.navigateByUrl('/')
    }
  }

  Save()
  {
    var validation = this.recipe.Validate();
    if(validation.IsError)
    {
      this.openSnackBar(validation.Detail + " - CHANGES NOT SAVED",null,5000);
    }
    else {
      this.idbService.addRecipe(this.recipe)
      this.recipe.ChangesSaved();
      this.openSnackBar("Saved successfully","OK",5000);
    }
    
  }
  openSnackBar(message: string, action: string,  showForMS:number = 3000) {
    this._snackBar.open(message, action, {
      duration: showForMS,
    });
  }
  ngOnInit() {
    
  }
  

}
