import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Recipe } from 'src/app/model/Recipe';

import { RecipeItemShared } from './recipe-item-shared';

@Component({
  selector: 'app-recipe-item-list',
  templateUrl: './recipe-item-list.component.html',
  styleUrls: ['./recipe-item-list.component.scss']
})
export class RecipeItemListComponent extends RecipeItemShared implements OnInit  {
  @Input() recipe: Recipe;

  ngOnInit() {
  }


}
