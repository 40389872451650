import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Recipe } from 'src/app/model/Recipe';
import { IdbService } from 'src/app/services/idb.service';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ShareRecipeComponent } from '../../sharing/recipe/recipe.component';
import { RecipeItemShared } from './recipe-item-shared';

@Component({
  selector: 'app-recipe-item',
  templateUrl: './recipe-item.component.html',
  styleUrls: ['./recipe-item.component.scss']
})
export class RecipeItemComponent extends RecipeItemShared implements OnInit  {
  @Input() recipe: Recipe;

  ngOnInit() {
  }


}
