export enum IngredientType {
    Liquid,
    Flour,
    Salt,
    Yeast,
    StarterOrLeaven,
    Other,
    Fats,
    Sugars
}

export namespace IngredientType {

    export function values() {
      return Object.keys(IngredientType).filter(
        (type) => isNaN(<any>type) && type !== 'values'
      );
    }
  }