import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RecipeListComponent } from './pages/recipe-list/recipe-list.component';
import { RecipeDetailComponent } from './pages/recipe-detail/recipe-detail.component';
import { ListIngredientsComponent } from './pages/ingredients/list-ingredients/list-ingredients.component';
import { ImportRecipeComponent } from './pages/sharing/recipe/importrecipe/import.component';
import { AboutComponent } from './pages/about/about.component';


const routes: Routes = [
  
{ path: '', component: RecipeListComponent },
{ path: 'ingredients', component: ListIngredientsComponent },
{ path: 'recipe/add', component: RecipeDetailComponent },
{ path: 'recipe/:id', component: RecipeDetailComponent },
{ path: 'import-recipe', component: ImportRecipeComponent },

{ path: 'about', component: AboutComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
