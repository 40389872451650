
<h2 mat-dialog-title>{{title}}</h2>

<mat-dialog-content>
  
    <mat-form-field>
        <mat-label>Name</mat-label>
        <input required matInput [(ngModel)]="step.Name" name="name"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Temperature</mat-label>
        <input type="number" required matInput [(ngModel)]="step.TemperatureInC" name="temperature"/>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Start</mat-label>
        <input type="datetime-local"  required matInput [ngModel]="step.StartDateTime | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="step.StartDateTime=StringToDateTime($event)">

      </mat-form-field>
      <mat-form-field>
        <mat-label>End</mat-label>
        <input type="datetime-local"  required matInput [ngModel]="step.EndDateTime | date:'yyyy-MM-ddTHH:mm'" (ngModelChange)="step.EndDateTime=StringToDateTime($event)">
      </mat-form-field>
 
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button (click)="Close()" aria-label="Close Add/Edit Step">Close</button>
    <button mat-raised-button color="primary" (click)="Save()" aria-label="Save Step">Save</button>
</mat-dialog-actions>
