import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
  import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
  import { Guid } from 'guid-typescript';
import { Step } from 'src/app/model/Step';
import { AddEditStepComponent } from './step/addedit/addedit.component';

@Component({
  selector: 'app-recipe-steps',
  templateUrl: './recipe-steps.component.html',
  styleUrls: ['./recipe-steps.component.scss']
})
export class RecipeStepsComponent implements OnInit {

    @Input() steps: Step[];
    displayedColumns: string[] = ['name', 'temperature', 'start','end','delete'];
    @Output() valueChange = new EventEmitter();
    @Output() RemoveStep = new EventEmitter();
    
    constructor(private dialog: MatDialog) { 

      
    }
  
    ngOnInit(): void {
      
    }
    openStepDialog(isNew:boolean, step: Step)
    {
      const dialogConfig = new MatDialogConfig();
  
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;

      dialogConfig.data = {
          isNew: isNew,
          title: isNew? "Add New Step": 'Edit Step',
          step:step
      };

      const dialogRef = this.dialog.open(AddEditStepComponent, dialogConfig);
  
      dialogRef.afterClosed().subscribe(
          (savedStep:Step) => {
            if(savedStep != null)
            {
              
           if(isNew)
           {
            this.steps.push(savedStep);
            this.steps = this.steps.slice();
           } 
           else
           {
            step = savedStep;
           }
          }
  
          this.valueChange.emit(this.steps);
          }
      );    
      
    }
  
    AddStep()
    {
      var step = new Step();
      step.Id = Guid.create().toString();
      this.openStepDialog(true,step);
    }
    EditStep(step: Step)
    {
      this.openStepDialog(false,step);
    }
    DeleteStep(step: Step)
    {
      if(confirm("are you sure?"))
      {
        this.RemoveStep.emit(step);
      }
    }
  }
  