
<mat-toolbar  color="primary" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" >  

<button mat-raised-button  routerLink="/" color="secondary" aria-label="Back to Recipes" >
  back to recipes
</button> 

</mat-toolbar>
<div fxLayout="column" fxLayoutGap="30px grid" class="container">
<div  >
<h1>Import Recipes</h1>
<p>Select .dough files here to have them imported - only import recipes from trusted sources</p>
<button mat-flat-button color="primary" aria-label="add image" (click)="uploader.click()">
    Import recipe(s)
  </button>
  <input 
    hidden 
    type="file" 
    multiple
    #uploader
    (change)="ImportRecipe($event)" accept=".dough"
/>

</div>
<div>
<h1>Export All Data</h1>
<p>Create a .bread file containing all recipes and settings that can be imported on other devices</p>
<button  flex mat-flat-button color="primary" aria-label="add image"  (click)="exportAllData()">
    Generate export data
  </button> 
  <a  class="md-padding"  mat-flat-button color="secondary"  download="allBreadBakerData.bread" [href]="recipeJsonAsDownload" *ngIf="recipeJsonAsDownload" >Download Export Data</a>

</div>
  <div >
  <h1>Import Data</h1>
  <p>Select .bread files here to have them imported - this will replace all app data</p>
  <button mat-flat-button color="primary" aria-label="add image" (click)="uploaderData.click()">
      Import data
    </button>
    <input 
      hidden 
      type="file" 
      
      #uploaderData
      (change)="ImportData($event)" accept=".bread"
  />

</div >
  <div   >
  <h1>Reset Data</h1>
  <p>This will clear and reset all app data</p>
  <button mat-flat-button color="warn" aria-label="reset data image" (click)="ResetData()">
      Reset data
    </button>
  </div>
</div>