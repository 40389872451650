import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Recipe } from 'src/app/model/Recipe';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-recipe',
  templateUrl: './recipe.component.html',
  styleUrls: ['./recipe.component.scss']
})
export class ShareRecipeComponent implements OnInit {
recipe:Recipe;
recipeJsonAsDownload: SafeUrl;
recipeWithoutPhotosJsonAsDownload: SafeUrl;
  constructor(
    private sanitization:DomSanitizer,
    private dialogRef: MatDialogRef<ShareRecipeComponent>,
    @Inject(MAT_DIALOG_DATA) data) {

    this.recipe = data.recipe;
    this.recipeJsonAsDownload = this.sanitization.bypassSecurityTrustUrl("data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.recipe)));
    this.recipe.Photos = [];
    this.recipeWithoutPhotosJsonAsDownload = this.sanitization.bypassSecurityTrustUrl("data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(this.recipe)));
}

  ngOnInit(): void {
  }

  DownloadClicked()
  {
    this.dialogRef.close();
  }

  MakeFileNameSafe(filename:  string)
  {
    return filename.replace(/[^a-z0-9\-]/gi, '_').toLowerCase() + ".dough";
  }

}
