import { IValidationResponse } from './interface/IValidationResponse';



export class ValidationResponse implements IValidationResponse {
    IsError: boolean;
    Detail: string;

    constructor(isError:boolean, detail:string)
    {
        this.IsError = isError;
        this.Detail = detail;
    };

}
