import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Recipe } from 'src/app/model/Recipe';
import { IdbService } from 'src/app/services/idb.service';
import { Router } from '@angular/router';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { ShareRecipeComponent } from '../../sharing/recipe/recipe.component';

@Component({
  template: ''
})

export abstract  class RecipeItemShared {

  @Output() recipeDeleted = new EventEmitter();
  constructor(private idbService: IdbService, private router: Router,private dialog: MatDialog) {   }



  DeleteRecipe(recipe: Recipe)
  {
    if(confirm("are you sure?"))
    {
      this.idbService.deleteRecipe(recipe);
      this.recipeDeleted.emit();
    }
  }
  ShareRecipe(recipe: Recipe)
  {
    this.openIngredientDialog(recipe);
  }
  openIngredientDialog(recipe: Recipe)
  {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    
    
    dialogConfig.data = {
        recipe:recipe
    };

    const dialogRef = this.dialog.open(ShareRecipeComponent, dialogConfig);

  }
}
