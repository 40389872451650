import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class UpdateService {

  constructor(updates: SwUpdate, private _snackBar: MatSnackBar) {
    updates.available.subscribe(event => {
         this.openSnackBar("There is an update available", "reload");
        
    }
    )};

    openSnackBar(message: string, action: string) {
        let snackBarRef = this._snackBar.open(message, action, {
          duration: 5000,
        })
        snackBarRef.onAction().subscribe(() => {
            window.location.reload();
          });
        ;
    }
}