import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AddEditIngredientComponent } from '../addeditingredient/addeditingredient.component';
import { Guid } from 'guid-typescript';
import { Ingredient } from 'src/app/model/Ingredient';
import { IdbService } from 'src/app/services/idb.service';
import { Recipe } from 'src/app/model/Recipe';
import { IngredientType } from 'src/app/enums/IngredientType.enum';
@Component({
  selector: 'app-list-ingredients',
  templateUrl: './list-ingredients.component.html',
  styleUrls: ['./list-ingredients.component.scss']
})
export class ListIngredientsComponent implements OnInit {
  ingredients: Ingredient[];
  displayedColumns: string[] = ['name', 'type','delete'];
  constructor(private idbService:IdbService,private dialog: MatDialog) { 
    idbService.connectToIDB();
    idbService.getAllIngredients().then(x=>this.ingredients = x);
  }

  ngOnInit(): void {
  }
  GetIngredientTypeByName(it: IngredientType): string
  {
    return IngredientType[it];
  }
  openIngredientDialog(isNew:boolean, ingredient: Ingredient)
  {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    
    
    dialogConfig.data = {
        isNew: isNew,
        title: isNew? "Add New Ingredient": 'Edit Ingredient',
        ingredient:ingredient
    };

    
    const dialogRef = this.dialog.open(AddEditIngredientComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        (savedIngredient:Ingredient) => {
          if(savedIngredient != null)
          {
            
         if(isNew)
         {
          this.idbService.addIngredient(savedIngredient);
         } 
         else
         {
          this.idbService.addIngredient(savedIngredient);
           if(confirm("update this ingredient across your existing recipes?"))
           {
            this.idbService.getAllRecipes().then((recipes: Recipe[])=>recipes.filter(x=>{
              x.Ingredients.filter(ri => 
                ri.Ingredient.Id == ingredient.Id
                ).forEach(ri=> ri.Ingredient = ingredient);
                this.idbService.addRecipe(x);
              })
                );
           }
         }
         this.idbService.getAllIngredients().then(x=>this.ingredients = x);
        }

       
        }
    );    
    
  }

  AddIngredient()
  {
    var ingredient = new Ingredient();
    ingredient.Id = Guid.create().toString();
    this.openIngredientDialog(true,ingredient);
  }
  EditIngredient(ingredient: Ingredient)
  {
    this.openIngredientDialog(false,ingredient);
  }
  DeleteIngredient(ingredient: Ingredient)
  {
    if(confirm("are you sure? - this ingredient will remain against existing recipes but won't be able to be added to new ones"))
    {
      this.idbService.deleteIngredient(ingredient);
      this.idbService.getAllIngredients().then(x=>this.ingredients = x);
    }
  }

}
