import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RecipeIngredient } from 'src/app/model/RecipeIngredient';
import { MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { AddEditRecipeIngredientComponent as AddEditRecipeIngredientComponent } from './addedit/addedit.component';
import { Guid } from 'guid-typescript';

@Component({
  selector: 'app-recipe-ingredients',
  templateUrl: './recipe-ingredients.component.html',
  styleUrls: ['./recipe-ingredients.component.scss']
})
export class RecipeIngredientsComponent implements OnInit {
  @Input() ingredients: RecipeIngredient[];
  displayedColumns: string[] = ['ingredient', 'percentage', 'weight','delete'];
  @Output() valueChange = new EventEmitter();
  @Output() RemoveIngredient = new EventEmitter();
  
  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
  }
  openIngredientDialog(isNew:boolean, ingredient: RecipeIngredient)
  {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    
    
    dialogConfig.data = {
        isNew: isNew,
        title: isNew? "Add New Ingredient": 'Edit Ingredient',
        ingredient:ingredient
    };

    
    const dialogRef = this.dialog.open(AddEditRecipeIngredientComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(
        (savedIngredient:RecipeIngredient) => {
          if(savedIngredient != null)
          {
         if(isNew)
         {
          this.ingredients.push(savedIngredient);
          this.ingredients = this.ingredients.slice();
         } 
         else
         {
           ingredient = savedIngredient;
         }
        }

        this.valueChange.emit(this.ingredients);
        }
    );    
    
  }

  AddIngredient()
  {
    var ingredient = new RecipeIngredient();
    ingredient.Id = Guid.create().toString();
    this.openIngredientDialog(true,ingredient);
  }
  EditIngredient(ingredient: RecipeIngredient)
  {
    this.openIngredientDialog(false,ingredient);
  }
  DeleteIngredient(ingredient: RecipeIngredient)
  {
    if(confirm("are you sure?"))
    {
      this.RemoveIngredient.emit(ingredient);
    }
  }
}
