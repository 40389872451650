import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { IdbService } from 'src/app/services/idb.service';
import { Recipe } from 'src/app/model/Recipe';
import { ViewType } from 'src/app/enums/ViewType.enum';
@Component({
  selector: 'app-recipes',
  templateUrl: './recipe-list.component.html',
  styleUrls: ['./recipe-list.component.scss']
})
export class RecipeListComponent implements OnInit {
  showSearch: boolean = false;
  recipes: Recipe[];
  searchTerm: string = '';
  ViewMode: ViewType = ViewType.Card;
  constructor(private idbService:IdbService) { 
    idbService.connectToIDB();
    this.getRecipes();
    if(localStorage.getItem('ViewMode') != null && localStorage.getItem('ViewMode') != "")
    {
    this.ViewMode = ViewType[localStorage.getItem('ViewMode')];
  }
  }

  recipeDeleted()
  {
    this.getRecipes();
  }
  ngOnInit() {
    
  }
  IsListView()
  {
    return this.ViewMode==ViewType.List;
  }
  IsCardView()
  {
    return this.ViewMode==ViewType.Card;
  }
  ChangeView()
  {
    if(this.ViewMode==ViewType.Card)
    {
      this.ViewMode = ViewType.List;
    }
    else if(this.ViewMode==ViewType.List)
    {
      this.ViewMode = ViewType.Card;
    }

    localStorage.setItem('ViewMode', ViewType[this.ViewMode]);
  }
  GetNextView()
  {
    if(this.ViewMode==ViewType.Card)
    {
      return ViewType[ViewType.List] + " View";
    }
    else if(this.ViewMode==ViewType.List)
    {
      return ViewType[ViewType.Card] + " View";
    }
  }
  toggleSearch()
  {
    this.showSearch = !this.showSearch;
    if(this.showSearch)
    {
      setTimeout(function(){document.getElementById("searchInput").focus();},300)
     
    }
  }
  searchRecipes(searchTerm)
  {
    this.searchTerm = searchTerm;
    this.getRecipes();
  }
  getRecipes()
  {
    if(this.searchTerm != "")
    {
      this.idbService.getAllRecipes().then(x=>
        {
          this.recipes = x.filter(y=>y.Name.toLowerCase().includes(this.searchTerm.toLowerCase())).sort((obj1, obj2) => {
            if (obj1.Name.toLowerCase() > obj2.Name.toLowerCase()) {
                return 1;
            }
        
            if (obj1.Name.toLowerCase() < obj2.Name.toLowerCase()) {
                return -1;
            }
        
            return 0;
        })
        });
    }
    else
    {
      
      this.idbService.getAllRecipes().then(x=>{this.recipes = x.sort((obj1, obj2) => {
        if (obj1.Name.toLowerCase() > obj2.Name.toLowerCase()) {
            return 1;
        }
    
        if (obj1.Name.toLowerCase() < obj2.Name.toLowerCase()) {
            return -1;
        }
    
        return 0;
    }); console.log(this.recipes)});
    }
  }

}
