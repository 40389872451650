<mat-toolbar color="primary">

    <mat-toolbar-row fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" >
        <button mat-button (click)="Close()" aria-label="Close recipe" >
            close
          </button> 
        <button  (click)="Save()" mat-raised-button aria-label="Save recipe"  color="secondary" [disabled]="!recipe.IsUnsaved()">
            Save
          </button>
        </mat-toolbar-row>

        </mat-toolbar>

<div fxLayout="column" fxLayoutGap="10px" fxLayoutAlign="space-around stretch"   >
  
<mat-form-field   appearance="fill">
    <mat-label>Name</mat-label>
    <input required matInput [(ngModel)]="recipe.Name" name="recipeName"/>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Description</mat-label>
    <textarea matInput [(ngModel)]="recipe.Description" name="Description"></textarea>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Dough Weight</mat-label>
    <input matInput required type="number" [(ngModel)]="recipe.Weight" name="weight" (change)="recipe.UpdateIngredientWeights()" />
  </mat-form-field>

  <mat-label>Hydration: {{recipe.GetHydration()}}%</mat-label>
  <mat-tab-group>
    <mat-tab label="Recipe">
      <app-recipe-ingredients (valueChange)="IngredientsUpdated($event)" (RemoveIngredient)="RemoveIngredient($event)" [ingredients]="recipe.Ingredients"></app-recipe-ingredients>
    </mat-tab>
    <mat-tab label="Steps">
      <app-recipe-steps [steps]="recipe.Steps" (valueChange)="StepsUpdated($event)" (RemoveStep)="RemoveStep($event)"></app-recipe-steps>
    </mat-tab>
    <mat-tab label="Notes">
      <mat-form-field appearance="fill">
        <mat-label>Notes</mat-label>
        <textarea matInput [(ngModel)]="recipe.Notes" name="Notes"></textarea>
      </mat-form-field>
    </mat-tab>
  </mat-tab-group>
  <mat-error  *ngIf="!recipe.IsTotalFlour100Percent()">
    Flour total must be <strong>100%</strong> - is currently <strong>{{recipe.GetTotalFlourPercentage()}}</strong>
  </mat-error>

  <app-image-list showDelete="true" [images]="recipe.Photos" (valueChange)="recipe.SetUnsavedChanges()" showAddButton="true" (RemoveImage)="RemovePhoto($event)"></app-image-list>
</div>

