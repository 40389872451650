import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-image-list',
  templateUrl: './image-list.component.html',
  styleUrls: ['./image-list.component.scss']
})
export class ImageListComponent implements OnInit {
  @Input() images: string[];
  @Input() showDelete: boolean;
  @Output() valueChange = new EventEmitter();
    @Output() RemoveImage = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  AddImage(evt)
  {
    var files = evt.target.files;

for (let file of files) {
 

      var reader = new FileReader();
      
      reader.onload =this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(file);
      
      /*compressImage(file, options).then(x=>{
        var reader = new FileReader();
      
      reader.onload =this._handleReaderLoaded.bind(this);
      reader.readAsDataURL(x);
        //this.AddToImages(x);
      });*/

  }; 
  }

  compressImage (base64) {
    const canvas = document.createElement('canvas')
    const img = document.createElement('img')
  
    return new Promise((resolve, reject) => {
      img.onload = function () {
        let width = img.width
        let height = img.height
        const maxHeight = 600;
        const maxWidth = 1000;
  
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }
        }
        canvas.width = width;
        canvas.height = height;
  
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
  
        resolve(canvas.toDataURL('image/webp', 0.8));
      }
      img.onerror = function (err) {
        reject(err);
      }
      img.src = base64;
    })
  }
 

  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    //this.AddToImages(binaryString);
    this.compressImage(binaryString).then(x=>this.AddToImages(x));
   }

   AddToImages(image)
{
  if(this.images == undefined)
  {
    this.images = [image];
  }
  else
  {
    this.images.push(image);
  }
  this.valueChange.emit();
}

DeleteImage(index)
    {
      if(confirm("are you sure?"))
      {
        this.RemoveImage.emit(index);
      }
    }
}
