
<mat-toolbar  color="primary" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10px" >  

  <button mat-raised-button  routerLink="/" color="secondary" >
    Recipes
  </button> 

  <button mat-raised-button  color="secondary" aria-label="add ingredient" (click)="AddIngredient()">
    <mat-icon>add</mat-icon>
  </button>
</mat-toolbar>
<mat-table [dataSource]="ingredients" class="mat-elevation-z8">
<ng-container matColumnDef="name">
  <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
  <mat-cell *matCellDef="let ingredient" (click)="EditIngredient(ingredient)"> {{ingredient.Name}} </mat-cell>
</ng-container>

<!-- Name Column -->
<ng-container matColumnDef="type" >
  <mat-header-cell *matHeaderCellDef class="typeCell"></mat-header-cell>
  <mat-cell *matCellDef="let ingredient" (click)="EditIngredient(ingredient)" class="typeCell"> {{GetIngredientTypeByName(ingredient.Type)}} </mat-cell>
</ng-container>


<ng-container matColumnDef="delete" >
  <mat-header-cell *matHeaderCellDef class="deleteCell"> </mat-header-cell>
  <mat-cell  *matCellDef="let ingredient" class="deleteCell">
      <button (click)="DeleteIngredient(ingredient);" mat-icon-button color="primary" aria-label="Delete Ingredient">
      <mat-icon>delete</mat-icon>
    </button>
  </mat-cell>
</ng-container>



<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
<mat-row *matRowDef="let row; columns: displayedColumns;" ></mat-row>
</mat-table>