import { IRecipeIngredient } from './interface/IRecipeIngredient';
import { IIngredient } from './interface/Iingredient';

export class RecipeIngredient implements IRecipeIngredient {
    
    
    Id: string;
    Percentage: number;
    Ingredient: IIngredient;

    private  Weight: number;

    SetWeight(weight: number) {
        this.Weight = weight;
    }
    GetWeightForDisplay():string {
        return (Math.round(this.Weight*100)/100) + "g";
    } 
    GetWeight():number {
        return this.Weight;
    } 
    
    SetBakersPercentageFromWeight(totalFlourWeight: number) {
        this.Percentage = (this.Weight /  totalFlourWeight)*100;

    }

}
