
<button mat-flat-button color="primary" aria-label="add image" (click)="uploader.click()">
    add photo
  </button>
  <input 
    hidden 
    type="file" 
    multiple
    #uploader
    (change)="AddImage($event)" accept=".png,.jpg,.jpeg"
/>

<div fxLayout="row wrap"  >
<div class="imgContainer"
      *ngFor="let image of images; let i=index"

      >
      <button mat-mini-fab color="warn" aria-label="delete image" (click)="DeleteImage(i)">
        <mat-icon>delete</mat-icon>
      </button>
      <img [src]="image"  >
      
    </div>
  </div>
